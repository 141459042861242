@media (max-device-width: 768px) {
  #app-container {
    display: none;
  }

  #mobile-message {
    display: block;
    text-align: center;
    margin-top: 50%;
    color: black;
    font-size: 20px;
  }
}

#mobile-message {
  display: none;
}

